import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      de: {
        translation: {
          ok: 'OK',
          back: 'Zurück',
          header: {
            title: 'Miniatur Wunderland × Ukraine',
            ukraine: 'Ukraine',
          },
          intro: {
            title: 'Tickets für Geflüchtete',
            text1: 'Liebe Gäste aus der Ukraine,',
            text2:
              'herzlich willkommen in Hamburg! Die Umstände, die Sie und Ihre Familie hierher geführt haben, tun uns von Herzen Leid. Wir werden unser Bestes tun, um Ihnen ein paar unbeschwerte und glückliche Stunden im Miniatur Wunderland zu bereiten und möchten Sie gerne einladen, unsere Ausstellung kostenlos zu besuchen.',
            text3:
              'Da wir an vielen Tagen schon wahnsinnig ausgebucht sind, müssen Sie sich bitte unbedingt vor Ihrem Besuch anmelden. Dafür können Sie auf dieser Seite kostenfreie Tickets buchen.',
            text4:
              'Da wir dieses besondere Angebot ausschließlich für die Betroffenen des Kriegs in der Ukraine zur Verfügung stellen, ist es wichtig, dass Sie am Besuchstag Ihren ukrainischen Pass an der Kasse vorzeigen. Eventuelle Begleitpersonen können ',
            text4LinkUrl:
              'https://www.miniatur-wunderland.de/besuch/tickets/#/',
            text4LinkText: 'hier reguläre Wunderland-Tickets erwerben',
            text5:
              'Wir wünschen Ihnen eine schöne Zeit im Miniatur Wunderland!',
          },
          gaps: {
            loading: 'Lädt Daten ...',
            selectDate: 'Datum wählen:',
            availableTimes: 'Verfügbare Zeiten:',
            placeAvailable: 'Platz verfügbar',
            placesAvailable: 'Plätze verfügbar',
            book: 'Buchen',
            noGapsAvailableOnThisDate:
              'An diesem Tag sind leider keine Termine mehr frei.',
          },
          form: {
            title: 'Buchung',
            firstName: 'Vorname',
            lastName: 'Nachname',
            mail: 'E-Mail',
            gender: {
              m: 'm',
              f: 'w',
              d: 'd',
            },
            persons: 'Personen',
            acceptTerms: {
              1: 'Ich habe die ',
              '2AgbUrl': 'https://www.miniatur-wunderland.de/service/agb/',
              '2AgbText': 'Allgemeinen Geschäftsbedingungen',
              3: ' und die ',
              '4PrivacyUrl':
                'https://www.miniatur-wunderland.de/service/datenschutz/',
              '4PrivacyText': 'Datenschutzerklärung',
              5: ' gelesen und akzeptiere diese.',
            },
            submit: 'Jetzt buchen',
            processing: 'Ihre Buchung wird bearbeitet...',
            thankYou: 'Vielen Dank!',
            confirmationText:
              'Wichtig: Sie erhalten Ihr Ticket per E-Mail. Bitte prüfen Sie auch den Spam-Ordner, wenn Sie in ein paar Minuten noch keine E-Mail erhalten haben.',
            requiredField: 'Dies ist ein Pflichtfeld',
            invalidEmail: 'Bitte eine gültige E-Mail eingeben',
            stringTooLong:
              'Der eingegebene Wert ist zu lang. Es sind nur 150 Zeichen möglich.',
            tooManyPersons: 'Es sind nur max. 15 Personen möglich.',
          },
          cancel: {
            title: 'Buchung stornieren',
            description:
              'Möchten Sie Ihre Buchung wirklich stornieren? Sie können zu verfügbaren Zeiten neue Tickets buchen.',
            button: 'Stornieren',
            bookingNotFound:
              'Diese Buchung wurde nicht gefunden. Bitte nehmen Sie Kontakt mit uns auf.',
            cancelled: 'Ihre Buchung wurde storniert.',
            cancelling: 'Ihre Buchung wird storniert, bitte warten...',
          },
          footer: {
            imprint: 'Impressum',
            privacy: 'Datenschutz',
          },
          error: {
            signup:
              'Fehler bei der Buchung. Falls es nicht an Ihrer Internetverbindung liegt, versuchen Sie es bitte später noch einmal.',
            gapCapacityTooSmall:
              'An diesem Termin sind leider nicht mehr genügend Plätze verfügbar. Bitte wählen Sie einen anderen.',
            takingLongerThanUsual:
              'Das dauert länger als üblich - sind Sie online?',
          },
        },
      },

      en: {
        translation: {
          ok: 'OK',
          back: 'Back',
          header: {
            title: 'Miniatur Wunderland × Ukraine',
            ukraine: 'Ukraine',
          },
          intro: {
            title: 'Tickets for Refugees',
            text1: 'Dear guests from Ukraine,',
            text2:
              'welcome to Hamburg! We are deeply sorry for the circumstances that brought you and your family here. We will do our best to give you a few carefree and happy hours in Miniatur Wunderland and would like to invite you to visit our exhibition for free.',
            text3:
              'Since we are already completely booked on many days, please make sure to register before your visit. To do so, you can book free tickets on this page.',
            text4:
              'As we provide this special offer exclusively for those affected by the war in Ukraine, it is important that you show your Ukrainian passport at the cash desk on the day of your visit. Any accompanying persons can ',
            text4Link: 'https://www.miniatur-wunderland.com/visit/tickets/#/',
            text4LinkText: 'book regular Wunderland tickets here',
            text5: 'We wish you a great time in Miniatur Wunderland!',
          },
          gaps: {
            loading: 'Loading data ...',
            selectDate: 'Select date:',
            availableTimes: 'Available times:',
            placeAvailable: 'Place available',
            placesAvailable: 'Places available',
            book: 'Book',
            noGapsAvailableOnThisDate:
              'Unfortunately, there are no tickets available on this date anymore.',
          },
          form: {
            title: 'Booking',
            firstName: 'First name',
            lastName: 'Last name',
            mail: 'E-Mail',
            gender: {
              m: 'm',
              f: 'f',
              d: 'd',
            },
            persons: 'Persons',
            acceptTerms: {
              1: 'I have read and agree to the ',
              '2AgbUrl':
                'https://www.miniatur-wunderland.com/services/terms-conditions/',
              '2AgbText': 'terms and conditions',
              3: ' and ',
              '4PrivacyUrl':
                'https://www.miniatur-wunderland.com/services/privacy/',
              '4PrivacyText': 'privacy policy',
              5: '.',
            },
            submit: 'Book now',
            processing: 'Your booking is being processed...',
            thankYou: 'Thank you!',
            confirmationText:
              'Important: You will receive your ticket by e-mail. Please check the spam folder if you have not received an e-mail in a few minutes.',
            requiredField: 'This is a required field',
            invalidEmail: 'Please enter a valid e-mail',
            stringTooLong:
              'The entered value is too long. It can only contain 150 characters.',
            tooManyPersons: 'Only 15 persons are allowed per booking.',
          },
          cancel: {
            title: 'Cancel Booking',
            description:
              'Do you really want to cancel your booking? You can book new tickets afterwards on available dates.',
            button: 'Cancel',
            bookingNotFound:
              'This booking could not be found. Please contact us.',
            cancelled: 'Your booking has been cancelled.',
            cancelling: 'Your booking is being cancelled, please wait...',
          },
          footer: {
            imprint: 'Imprint',
            privacy: 'Privacy',
          },
          error: {
            signup:
              'An error occured. Please make sure you are online or else try again later.',
            gapCapacityTooSmall:
              'Unfortunately, there are not enough places available at your selected time. Please choose another.',
            takingLongerThanUsual:
              'This takes longer than usual - are you online?',
          },
        },
      },
      ua: {
        translation: {
          ok: 'ОК',
          back: 'Назад',
          header: {
            title: 'Miniatur Wunderland × Ukraine',
            ukraine: 'Україна',
          },
          intro: {
            title: 'Квитки для випусків',
            text1: 'Шановні гості з України!',
            text2:
              "Ми дуже надгорні від ситуацій, що призвели до вас та вашу сім'ю тут. Ми будемо спробувати найкраще для вас надати вам декілька простих і веселих годин у Miniatur Wunderland і будемо хочемо привітати вас на нашій виставці безкоштовно.",
            text3:
              'Оскільки ми вже повністю заброньовані на декілька днів, будь ласка, переконайтеся, що ви зареєструєтеся до вашої відвідування. Щоб це зробити, ви можете забронювати безкоштовні квитки на цій сторінці.',
            text4:
              'Оскільки ми надаємо це особливе пропозицію тільки для випусків, які пошкоджені війною в Україні, це необхідно, щоб ви показали свою паспортну фотографію на касі на день вашої відвідування. Будь-ласка, зазначте, що ви можете ',
            text4LinkUrl:
              'https://www.miniatur-wunderland.com/visit/tickets/#/',
            text4LinkText: 'забронювати звичайні квитки тут',
            text5:
              'Ми надаємо вам майже все, що ви очікуєте в Miniatur Wunderland!',
          },
          gaps: {
            loading: 'Завантаження даних ...',
            selectDate: 'Виберіть дату:',
            availableTimes: 'Доступні часи:',
            placeAvailable: 'Місце доступне',
            placesAvailable: 'Місця доступні',
            book: 'Забронювати',
            noGapsAvailableOnThisDate:
              'На даний момент на цю дату немає квитків.',
          },
          form: {
            title: 'Бронювання',
            firstName: 'Ім’я',
            lastName: 'Прізвище',
            mail: 'E-Mail',
            gender: {
              m: 'ч',
              f: 'ж',
              d: 'с',
            },
            persons: 'Людини',
            acceptTerms: {
              1: 'Я прочитав і погоджуюся з ',
              '2AgbUrl':
                'https://www.miniatur-wunderland.com/services/terms-conditions/',
              '2AgbText': 'Умови користування',
              3: ' та ',
              '4PrivacyUrl':
                'https://www.miniatur-wunderland.com/services/privacy-policy/',
              '4PrivacyText': 'Політикою конфіденційності',
              5: '.',
            },
            submit: 'Забронювати',
            processing: 'Ваше бронювання обробляється...',
            thankYou: 'Дякуємо!',
            confirmationText:
              'Важливо: Ви отримаєте квиток на електронну пошту. Будь ласка, перевірте спам-каталог, якщо ви не отримали повідомлення в кілька хвилин.',
            requiredField: 'Це обов’язкове поле',
            invalidEmail: 'Будь ласка, введіть коректну електронну пошту',
            stringTooLong:
              'Введене значення занадто довге. Може містити лише 150 символів.',
            tooManyPersons: 'Максимально допустимо 15 людей на бронювання.',
          },
          cancel: {
            title: 'Відміна бронювання',
            description: 'Ви впевнені, що хочете відмінити бронювання?',
            button: 'Відмінити',
            bookingNotFound: 'Бронювання не знайдено',
            cancelled: 'Бронювання відмінено',
            cancelling: 'Відміна бронювання...',
          },
          footer: {
            imprint: 'Про нас',
            privacy: 'Політика конфіденційності',
          },
          error: {
            signup: 'Помилка при бронюванні. Будь ласка, спробуйте ще раз.',
            gapCapacityTooSmall:
              'Не вистачає місць на вибраний час. Будь ласка, виберіть інший час.',
            takingLongerThanUsual:
              'Вибачте, але зараз бронювання займає довше за звичайний час. Будь ласка, виберіть інший час.',
          },
        },
      },
    },
  })

export default i18n
