import React, { useState, useEffect, useRef } from 'react'
import { Gap, BookingFormData, Lang } from './types'

import Header from './Header'
import Footer from './Footer'
import GapSelection from './gaps/Gaps'
import Form from './BookingForm'
import Spinner from './components/spinner'
import { useTranslation } from 'react-i18next'

function App() {
  const { t, i18n } = useTranslation()
  const [showCancelScreen, setShowCancelScreen] = useState(false)
  const [cancelBookingId, setCancelBookingId] = useState<string | null>(null)
  const [cancelBookingNotFound, setCancelBookingNotFound] = useState(false)
  const [cancelling, setCancelling] = useState(false)
  const [cancelled, setCancelled] = useState(false)
  const [selectedGap, setSelectedGap] = useState<number | null>(null)
  const [bookingError, setBookingError] = useState<string | null>(null)
  const [submitted, setSubmitted] = useState(false)
  const [showSubmitTakingVeryLong, setShowSubmitTakingVeryLong] =
    useState(false)
  const submitTimeout1 = useRef<ReturnType<typeof setTimeout> | null>(null)
  const submitTimeout2 = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [registered, setRegistered] = useState(false)
  const [lang, setLang] = useState<Lang>('ua')
  const [gaps, setGaps] = useState<Gap[]>([])
  const [gapsLoaded, setGapsLoaded] = useState(false)

  const changeLang = (newLang: Lang) => {
    setLang(newLang)
    i18n.changeLanguage(newLang)
  }

  const cancelBooking = () => {
    setCancelling(true)
    setCancelled(false)
    console.log(cancelBookingId)

    fetch('/api/bookings/cancel/' + cancelBookingId, {
      method: 'POST',
    })
      .then((res) => res.json())
      .then(({ success }: { success: boolean }) => {
        if (success) {
          setCancelled(true)
        } else {
          setCancelBookingNotFound(true)
        }
        setCancelling(false)
      })
  }

  const handleSubmit = (formData: BookingFormData) => {
    setSubmitted(true)
    submitBooking(formData)
  }

  const submitBooking = (formData: BookingFormData) => {
    submitTimeout1.current = setTimeout(() => {
      setShowSubmitTakingVeryLong(true)
    }, 7000)
    submitTimeout2.current = setTimeout(() => {
      setBookingError('error')
    }, 20000)

    fetch('/api/bookings/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...formData, lang, gap: selectedGap }),
    })
      .then((res) => res.json())
      .then((res) => {
        clearTimeout(submitTimeout1.current!)
        clearTimeout(submitTimeout2.current!)
        if (!res.success) {
          console.log('error signing up')
          setBookingError(res.errorCode ?? 'error')
          setSubmitted(false)
        } else {
          setRegistered(true)
        }
      })
  }

  const reset = () => {
    setSubmitted(false)
    setRegistered(false)
    setBookingError(null)
    setSelectedGap(null)
  }

  const handleGapSelection = (gap: Gap) => {
    setSelectedGap(gap.id)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    // if lang is provided by URL, set it, else default to Ukrainian
    const lang = searchParams.get('hl')

    i18n.changeLanguage(lang === 'de' || lang === 'en' ? lang : 'ua')

    // if cancel URL params are provided, show cancel screen

    const cancel = searchParams.get('cancel')
    if (cancel && cancel === '1') {
      setShowCancelScreen(true)

      const bookingToCancel = searchParams.get('booking')
      const bookingRegExp = new RegExp('^([a-zA-Z0-9]{50})$')

      if (!bookingToCancel || !bookingRegExp.test(bookingToCancel)) {
        setCancelBookingNotFound(true)
        return
      }
      setCancelBookingId(bookingToCancel)
    } else {
      fetch('/api/gaps')
        .then((res) => res.json())
        .then((res: BackendDataGaps) => {
          setGapsLoaded(true)
          if (!res.success) {
            console.log('backend error')
            return
          }
          setGaps(
            res.gaps.map((gap) => ({
              id: gap.id,
              date: gap.timestamp.substring(0, 10),
              time: gap.timestamp.substring(11, 16),
              capacity: gap.capacity,
            }))
          )
        })
    }
  }, [])

  useEffect(() => {
    if (selectedGap !== null) window.location.hash = 'booking'
  }, [selectedGap])

  return (
    <div className="App">
      <Header lang={lang} onLanguageChange={changeLang} />
      <div className="flex justify-center ">
        <div className=" px-4 sm:px-10 md:px-20 lg:px-28 lg:max-w-screen-xl flex-1 flex flex-col">
          {showCancelScreen ? (
            <div className="flex flex-col justify-center items-center px-3 py-8">
              <h1 className="text-center text-primary text-2xl font-bold mt-2 mb-12">
                {t('cancel.title')}
              </h1>
              {cancelBookingNotFound ? (
                <div>
                  <p className="text-center text-lg">
                    {t('cancel.bookingNotFound')}
                  </p>
                </div>
              ) : cancelled ? (
                <div>
                  <p className="text-center text-lg">{t('cancel.cancelled')}</p>
                  <div className="flex justify-center mt-8">
                    <button
                      onClick={() => {
                        window.location.href = 'https://' + window.location.host
                      }}
                    >
                      {t('back')}
                    </button>
                  </div>
                </div>
              ) : cancelling ? (
                <div>
                  <div className="h-24 mb-6 md:mb-8">
                    <Spinner />
                  </div>
                  <p className="text-center text-lg">
                    {t('cancel.cancelling')}
                  </p>
                </div>
              ) : (
                <div>
                  <p className="text-center text-lg">
                    {t('cancel.description')}
                  </p>
                  <div className="flex justify-center mt-8">
                    <button onClick={cancelBooking} disabled={cancelling}>
                      {t('cancel.button')}
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              {selectedGap === null && (
                <section id="intro">
                  <div className="mb-6 sm:mb-7 lg:mb-10 flex flex-col">
                    <div className="mt-8 sm:mt-10 lg:mt-1 pr-3 text-left mb-8 sm:mb-10 lg:mb-12">
                      <h1 className="text-3xl lg:text-4xl text-left flex flex-row flex-wrap text-primary">
                        {t('intro.title')}
                      </h1>
                    </div>

                    <p className="lg:text-lg">{t('intro.text1')}</p>
                    <p className="lg:text-lg mt-5">{t('intro.text2')}</p>
                    <p className="lg:text-lg mt-5">{t('intro.text3')}</p>
                    <p className="lg:text-lg mt-5">
                      {t('intro.text4')}{' '}
                      <a
                        className="text-primary hover:underline"
                        href={t('intro.text4LinkUrl')}
                      >
                        {t('intro.text4LinkText')}
                      </a>
                      .
                    </p>
                    <p className="lg:text-lg mt-5">{t('intro.text5')}</p>
                  </div>
                </section>
              )}

              <section id="booking">
                <div className="mt-6 lg:mt-8 px-0 md:px-1">
                  {registered ? (
                    <div>
                      <h1 className="text-2xl lg:text-3xl text-left mb-7">
                        {t('form.thankYou')}
                      </h1>
                      <p className="ml-4 text-lg">
                        {t('form.confirmationText')}
                      </p>
                    </div>
                  ) : submitted ? (
                    <div className="w-full h-full flex flex-col justify-center items-center">
                      {bookingError ? (
                        <div>
                          <p className="text-lg text-center">
                            {t(
                              bookingError === 'b03'
                                ? 'error.gapCapacityTooSmall'
                                : 'error.signup'
                            )}
                          </p>
                          <div className="mt-8 flex flex-col items-center">
                            <button onClick={reset}>{t('back')}</button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="h-24 mb-6 md:mb-8 mt-8">
                            <Spinner />
                          </div>
                          <p className="text-lg text-center">
                            {t('form.processing')}
                          </p>
                          <p className="text-center mt-4 md:mt-5">
                            {showSubmitTakingVeryLong
                              ? t('error.takingLongerThanUsual')
                              : ' '}
                          </p>
                        </div>
                      )}
                    </div>
                  ) : !gapsLoaded ? (
                    <div>
                      <p> {t('gaps.loading')}</p>
                    </div>
                  ) : selectedGap === null ? (
                    <div>
                      <GapSelection
                        gaps={gaps}
                        lang={lang}
                        onSelect={handleGapSelection}
                      />
                    </div>
                  ) : (
                    <Form
                      onSubmit={handleSubmit}
                      submitted={submitted}
                      gap={
                        gaps.filter((el) => el.id === selectedGap).length > 0
                          ? gaps.filter((el) => el.id === selectedGap)[0]
                          : ({ id: 0, date: '', time: '', capacity: 0 } as Gap)
                      }
                    />
                  )}
                </div>
              </section>
            </div>
          )}
        </div>
      </div>
      <Footer lang={lang} />
    </div>
  )
}

type BackendDataGaps = {
  success: boolean
  errorCode?: string
  gaps: { id: number; timestamp: string; capacity: number }[]
}

export default App
