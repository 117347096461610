import React, { useState, useEffect } from 'react'
import { Gap, Lang } from '../types'
import { useTranslation } from 'react-i18next'
import DatePickerModule, {
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.sass'
import { Locale, format } from 'date-fns'
import enGb from 'date-fns/locale/en-GB'
import deDe from 'date-fns/locale/de'
import uaUa from 'date-fns/locale/uk'

// eslint-disable-next-line no-unused-vars
type LocaleItems = { [K in Lang]: Locale }
const locales = {
  en: enGb,
  de: deDe,
  ua: uaUa,
} as LocaleItems
for (const [key, value] of Object.entries(locales)) {
  registerLocale(key, value)
}

function Gaps({
  gaps,
  lang,
  onSelect,
}: {
  gaps: Gap[]
  lang: Lang
  // eslint-disable-next-line no-unused-vars
  onSelect: (gap: Gap) => void
}) {
  const { t } = useTranslation()
  const [availableDates, setAvailableDates] = useState<string[]>([])
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())

  const handleGapSelection = (gap: Gap) => onSelect(gap)

  const handleSelectedDateChange = (newDate: Date | null) => {
    if (newDate) setSelectedDate(newDate)
  }

  useEffect(() => {
    const newAvailableDates: string[] = []
    gaps.forEach((gap) => {
      if (newAvailableDates.indexOf(gap.date) === -1)
        newAvailableDates.push(gap.date)
      setAvailableDates(newAvailableDates)
    })
  }, [gaps])

  useEffect(() => {
    setDefaultLocale(locales[lang] as any)
  }, [lang])

  return (
    <div className="gaps flex flex-col">
      <h2 className="text-xl font-semibold mt-1 mb-6">
        {t('gaps.selectDate')}
      </h2>
      <DatePickerModule
        locale={locales[lang]}
        key={lang}
        selected={selectedDate}
        onChange={handleSelectedDateChange}
        dateFormat="PPP" //{lang === 'de' ? 'dd.MM.yy' : 'yy/MM/dd'}
        minDate={new Date()}
        includeDates={availableDates.map((date) => new Date(date))}
        withPortal={window.innerWidth < 600}
      />
      {gaps.filter((gap) => gap.date === format(selectedDate, 'yyyy-MM-dd'))
        .length === 0 ? (
        <div className="mt-14 opacity-80">
          <p className="pl-4 md:pl-7">{t('gaps.noGapsAvailableOnThisDate')}</p>
        </div>
      ) : (
        <div className="gaps-list mt-14 flex-1 flex flex-col space-y-5">
          <h2 className="text-xl font-semibold mt-1 mb-2">
            {t('gaps.availableTimes')}
          </h2>
          {gaps
            .filter((gap) => gap.date === format(selectedDate, 'yyyy-MM-dd'))
            .map((gap, i) => (
              <div
                key={i}
                className={`gaps-item flex flex-row justify-between items-center space-x-3 p-3 md:p-4 border rounded-lg`}
                onClick={() => handleGapSelection(gap)}
              >
                <div className="text-lg pl-1 md:pl-3 md:text-xl font-semibold">
                  {gap.time}
                </div>
                <div>
                  {gap.capacity}{' '}
                  {t(
                    gap.capacity === 1
                      ? 'gaps.placeAvailable'
                      : 'gaps.placesAvailable'
                  )}
                </div>
                <div>
                  <button
                    onClick={() => handleGapSelection(gap)}
                    title={t('gaps.book')}
                  >
                    {t('gaps.book')}
                  </button>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

export default Gaps
