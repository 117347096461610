import React from 'react'
import { useTranslation } from 'react-i18next'
import { Lang } from './types'
import logo from './assets/wunderland-logo-weiss-rgb.png'
import flagUa from './assets/ua.svg'
import flagDe from './assets/de.svg'
import flagEn from './assets/en.svg'

const lngs = {
  de: { nativeName: 'Deutsch' },
  en: { nativeName: 'English' },
  ua: { nativeName: 'Українська' },
}

interface Props {
  lang: Lang
  // eslint-disable-next-line no-unused-vars
  onLanguageChange: (lang: Lang) => void
}
export default function Header({ lang, onLanguageChange }: Props) {
  const { t, i18n } = useTranslation()

  const changeLang = (newLang: Lang) => {
    onLanguageChange(newLang)
    i18n.changeLanguage(newLang)
  }

  return (
    <header>
      <div
        id="banner"
        className="banner flex flex-row justify-center align-center content-center"
        style={{
          backgroundImage: `url(${flagUa})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      >
        <div className="flex-initial w-20 md:w-36 ml-2 md:ml-10 lg:ml-20 mr-1 md:mr-4 flex justify-center items-center">
          <img
            src={logo}
            className="w-18 md:w-30 h-18 md:h-30"
            style={{ filter: 'drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.35))' }}
          />
        </div>

        <div
          className="flex flex-1 flex-col font-semibold text-white text-center text-xl sm:text-3xl justify-center mr-2 md:mr-10 lg:mr-20"
          style={{
            textShadow: '0.05em 0.05em 0.15em rgba(0, 0, 0, 0.3)',
            lineHeight: window.innerWidth < 640 ? '0.3em' : '1em',
          }}
        >
          <div className="leading-5 sm:leading-6">Miniatur Wunderland</div>

          <div>
            <span className="text-3xl lg:text-4xl leading-5 sm:leading-6 md:leading-7">
              ×
            </span>
          </div>

          <div className="leading-5 smleading-6  md:leading-7">
            {t('header.ukraine')}
          </div>
        </div>
      </div>

      <div className="flex flex-row mt-5 md:mt-8 mr-2 justify-end items-center">
        {Object.keys(lngs)
          .reverse()
          .filter((el) => el !== lang)
          .map((lng) => (
            <button
              key={lng}
              className=" not-italic text-xl mb-2 mr-3 md:mr-5 p-1.5 md:p-1 lg:p-1.5 text-white md:text-xl lg:text-2xl"
              style={{
                fontWeight: i18n.resolvedLanguage === lng ? '800' : '700',
                backgroundImage: `url(${
                  lng === 'de' ? flagDe : lng === 'en' ? flagEn : flagUa
                })`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                transform: 'scale(0.95)',
                textShadow: '0.05em 0.05em 0.15em rgba(0, 0, 0, 1)',
              }}
              type="submit"
              onClick={() => changeLang(lng as Lang)}
            >
              <span className="not-italic">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                {/* }&nbsp;{lng.toUpperCase()}&nbsp; */}
              </span>
            </button>
          ))}
      </div>
    </header>
  )
}
