import React from 'react'

import { MutatingDots } from 'react-loader-spinner'

export default function Spinner() {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <MutatingDots
        ariaLabel="loading-indicator"
        color="#005bbb"
        secondaryColor="#ffd500"
        radius={14}
        width={120}
        height={120}
      />
    </div>
  )
}
