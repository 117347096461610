import React from 'react'
import { BookingFormData, Gap } from './types'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

function SignUpForm({
  gap,
  onSubmit,
  submitted,
}: {
  // eslint-disable-next-line no-unused-vars
  gap: Gap
  // eslint-disable-next-line no-unused-vars
  onSubmit: (formData: BookingFormData) => void
  submitted: boolean
}) {
  // const [addressError, setAddressError] = useState(false)

  const { t } = useTranslation()

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = (person: BookingFormData, formError: any) => {
    onSubmit(person)
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(254, t('form.stringTooLong'))
      .required(t('form.requiredField')),
    lastName: Yup.string()
      .max(254, t('form.stringTooLong'))
      .required(t('form.requiredField')),
    gender: Yup.string().length(1).required(t('form.requiredField')),
    email: Yup.string()
      .email(t('form.invalidEmail'))
      .required(t('form.requiredField')),
    persons: Yup.number().positive().min(1).max(15, t('form.tooManyPersons')),
    termsAccepted: Yup.boolean().oneOf([true], t('form.requiredField')),
  })

  //TODO: process available persons

  return (
    <div id="signup_form" className="flex flex-row flex-wrap justify-evenly">
      <h1 className="text-2xl text-left ml-0 md:ml-1 mb-12 mt-3 sm:mt-0 sm:mb-6 mr-0 md:mr-8 lg:mr-30 lg:pr-12">
        {t('form.title')}
      </h1>
      <div className="w-full md:w-2/3">
        <div className="">
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              gender: '',
              email: '',
              persons: 0,
              termsAccepted: false,
            }}
            onSubmit={(values, errors) => {
              handleSubmit(values, errors)
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-4 lg:mb-6 transition">
                  <div className="">
                    <div className="input-group has-validation mb-9 sm:mb-0">
                      <Field
                        type="text"
                        name="firstName"
                        className={`text-input transition input-shadow
                        w-full md:w-3/4 lg:text-lg form-control ${
                          touched.firstName &&
                          !!errors.firstName &&
                          'is-invalid'
                        }`}
                        style={{
                          fontWeight:
                            (touched.firstName &&
                              values.firstName.length > 3) ||
                            values.lastName.length > 0
                              ? 500
                              : 400,
                        }}
                        placeholder={t('form.firstName')}
                      />
                    </div>
                    {touched.firstName && errors.firstName && (
                      <div className="text-red-500 mt-2">
                        {errors.firstName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-4 lg:mb-6 transition">
                  <div className="">
                    <div className="input-group has-validation mb-8 sm:mb-0">
                      <Field
                        type="text"
                        name="lastName"
                        className={`text-input transition input-shadow w-full md:w-3/4 lg:text-lg form-control ${
                          touched.lastName && !!errors.lastName && 'is-invalid'
                        }`}
                        style={{
                          fontWeight:
                            values.firstName.length > 0 &&
                            ((touched.lastName && values.lastName.length > 3) ||
                              values.email.length > 0)
                              ? 500
                              : 400,
                        }}
                        placeholder={t('form.lastName')}
                      />
                    </div>
                    {touched.lastName && errors.lastName && (
                      <div className="text-red-500 mt-2">{errors.lastName}</div>
                    )}
                  </div>
                </div>
                <div className="mb-5 lg:mb-6 transition">
                  <div className="">
                    <div className="input-group has-validation mb-8 sm:mb-0">
                      <div
                        role="group"
                        aria-labelledby="gender"
                        className="flex flex-row justify-evenly md:flex-row w-full md:w-3/4 lg:text-lg"
                      >
                        <label
                          className="mr-4 transition"
                          style={{
                            opacity:
                              values.gender.length > 0 && values.gender !== 'm'
                                ? '0.25'
                                : '1',
                            fontWeight:
                              values.gender.length > 0 && values.gender === 'm'
                                ? 500
                                : 400,
                          }}
                        >
                          <Field
                            type="radio"
                            name="gender"
                            value="m"
                            className={`form-control ${
                              touched.gender && !!errors.gender && 'is-invalid'
                            }`}
                            style={{
                              transform: 'scale(1.3)',
                              fontWeight:
                                values.gender.length > 0 &&
                                values.gender === 'm'
                                  ? 500
                                  : 400,
                            }}
                            onChange={handleChange('gender')}
                            onBlur={handleBlur('gender')}
                          />
                          &nbsp;&nbsp;{t('form.gender.m')}
                        </label>
                        <label
                          className="mr-4 transition"
                          style={{
                            opacity:
                              values.gender.length > 0 && values.gender !== 'f'
                                ? '0.25'
                                : '1',
                            fontWeight:
                              values.gender.length > 0 && values.gender === 'f'
                                ? 500
                                : 400,
                          }}
                        >
                          <Field
                            type="radio"
                            name="gender"
                            value="f"
                            className={`form-control ${
                              touched.gender && !!errors.gender && 'is-invalid'
                            }`}
                            style={{
                              transform: 'scale(1.3)',
                              fontWeight:
                                values.gender.length > 0 &&
                                values.gender === 'f'
                                  ? 500
                                  : 400,
                            }}
                            onChange={handleChange('gender')}
                            onBlur={handleBlur('gender')}
                          />
                          &nbsp;&nbsp;{t('form.gender.f')}
                        </label>
                        <label
                          className="mr-4 transition"
                          style={{
                            opacity:
                              values.gender.length > 0 && values.gender !== 'd'
                                ? '0.25'
                                : '1',
                            fontWeight:
                              values.gender.length > 0 && values.gender === 'd'
                                ? 500
                                : 400,
                          }}
                        >
                          <Field
                            type="radio"
                            name="gender"
                            value="d"
                            className={`form-control ${
                              touched.gender && !!errors.gender && 'is-invalid'
                            }`}
                            style={{
                              transform: 'scale(1.3)',
                              fontWeight:
                                values.gender.length > 0 &&
                                values.gender === 'd'
                                  ? 500
                                  : 400,
                            }}
                            onChange={handleChange('gender')}
                            onBlur={handleBlur('gender')}
                          />
                          &nbsp;&nbsp;{t('form.gender.d')}
                        </label>
                      </div>
                    </div>
                    {touched.gender && errors.gender && (
                      <div className="text-red-500 mt-2">{errors.gender}</div>
                    )}
                  </div>
                </div>

                <div className="mb-4 lg:mb-6 transition">
                  <div className="">
                    <div className="input-group has-validation mb-8 sm:mb-0">
                      <Field
                        type="mail"
                        name="email"
                        className={`text-input transition input-shadow w-full md:w-3/4 lg:text-lg form-control ${
                          touched.email && !!errors.email && 'is-invalid'
                        }`}
                        style={{
                          fontWeight:
                            touched.persons || values.persons > 0 || submitted
                              ? 500
                              : 400,
                        }}
                        placeholder={t('form.mail')}
                      />
                    </div>
                    {touched.email && errors.email && (
                      <div className="text-red mt-2">{errors.email}</div>
                    )}
                  </div>
                </div>
                <div className="mb-7 pl-1 transition">
                  <div className="flex flex-col items-center sm:items-start mb-8 sm:mb-0">
                    <div className="input-group has-validation   ">
                      {t('form.persons')} &nbsp; &nbsp;
                      <Field
                        type="number"
                        name="persons"
                        min={1}
                        max={Math.min(15, gap.capacity)}
                        size={3}
                        className={`w-13 lg:text-lg text-input transition input-shadow form-control ${
                          touched.persons && !!errors.persons && 'is-invalid'
                        }`}
                        style={{
                          fontWeight: touched.persons ? 500 : 400,
                        }}
                      />
                      {/* }<div className="invalid-feedback">
                        {errors.persons}&nbsp;
                      </div> */}
                    </div>
                    {touched.persons && errors.persons && (
                      <div className="text-red-500 mt-2">{errors.persons}</div>
                    )}
                  </div>
                </div>
                <div className="mb-7 pl-1 transition">
                  <div className="">
                    <div className="input-group has-validation">
                      <label>
                        <Field type="checkbox" name="termsAccepted" />
                        &nbsp;{t('form.acceptTerms.1')}{' '}
                        <a
                          className="text-primary hover:underline"
                          href={t('form.acceptTerms.2AgbUrl')}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('form.acceptTerms.2AgbText')}
                        </a>{' '}
                        {t('form.acceptTerms.3')}{' '}
                        <a
                          className="text-primary hover:underline"
                          href={t('form.acceptTerms.4PrivacyUrl')}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('form.acceptTerms.4PrivacyText')}
                        </a>
                        {t('form.acceptTerms.5')}
                        {/* }<div className="invalid-feedback">
                        {errors.persons}&nbsp;
                      </div> */}
                      </label>
                    </div>
                    {touched.termsAccepted && errors.termsAccepted && (
                      <div className="text-red-500 mt-2">
                        {errors.termsAccepted}
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="mb-5 pt-2 transition"
                  style={{
                    opacity:
                      values.firstName.length > 1 &&
                      values.lastName.length > 1 &&
                      values.email.length > 3 &&
                      values.persons > 0 &&
                      values.termsAccepted === true &&
                      !errors.email
                        ? 1
                        : 0.35,
                  }}
                >
                  <div className="flex flex-col items-center sm:items-start mt-2">
                    <button
                      type="submit"
                      className={`${submitted ? 'disabled' : ''}`}
                      disabled={submitted}
                    >
                      {t('form.submit')}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default SignUpForm
