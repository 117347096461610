import React from 'react'
import { useTranslation } from 'react-i18next'
import { Lang } from './types'

export default function Footer({ lang }: { lang: Lang }) {
  const { t } = useTranslation()
  return (
    <footer className="text-gray-700 mb-4">
      <div className="flex flex-col md:flex-row flex-wrap px-20 mt-28 md:mt-40 lg:mt-48 justify-center text-sm space-y-4 md:space-y-0 text-center md:text-left">
        <a
          className="mx-4"
          href={
            lang === 'de'
              ? 'https://www.miniatur-wunderland.de/service/impressum/'
              : 'https://www.miniatur-wunderland.com/services/imprint/'
          }
          title="Impressum"
        >
          {t('footer.imprint')}
        </a>
        <a
          className="mx-4"
          href={
            lang === 'de'
              ? 'https://www.miniatur-wunderland.de/service/datenschutz/'
              : 'https://www.miniatur-wunderland.com/services/privacy/'
          }
          title="Datenschutz"
        >
          {t('footer.privacy')}
        </a>
        <a
          className="mx-4"
          href={
            lang === 'de'
              ? 'https://www.miniatur-wunderland.de'
              : 'https://www.miniatur-wunderland.com'
          }
          title="Homepage"
        >
          Miniatur&nbsp;Wunderland
        </a>
      </div>
      <div className="text-center text-sm mt-1"></div>
    </footer>
  )
}
